import routerOptions0 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}