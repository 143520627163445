import payload_plugin_Uj19vU8f8S from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_firebase-functions@4.9.0_f_hnxytxgnuqmy6f3am4ulpstx2u/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_Q5bIi1lQFP from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_67WpaHIo4T from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_00A0iku6p4 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_yuVpx2BuF1 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-site-config@2.2.18_vite@5.4.8_vue@3.5.11/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_lVkB9OdY1z from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Xsl3dYcpES from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3nZPHodPC9 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0ranjmRsAj from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_dRhX6C5ccH from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.6.2_vue@3.5.11/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_9jT3W5OS8r from "/home/runner/work/web-app/web-app/.nuxt/runtime/plugin.ts";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web-app/web-app/.nuxt/components.plugin.mjs";
import prefetch_client_Kfk6Y9ARSL from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_J3lTlazylS from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_Gt9lJBsJi3 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_U7zLVPBgb6 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_1rze2VsG7c from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_QbA93dd8mi from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+icon@1.5.4_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_J56iT8A0ys from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_firebase-functions@4.9.0_f_hnxytxgnuqmy6f3am4ulpstx2u/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/home/runner/work/web-app/web-app/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/home/runner/work/web-app/web-app/.nuxt/vuefire-plugin.mjs";
import plugin_client_OefBY4RPZv from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_firebase-functions@4.9.0_f_hnxytxgnuqmy6f3am4ulpstx2u/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import scrollbars_client_00TO0odwi6 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_QvOK6CALx7 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_gbKpdJMtox from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_vite@5.4.8_vue@3.5.11/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import maska_70kEk31DXW from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-components@2.2.2_nuxt@3.13.2_vite@5.4.8_vue@3.5.11/node_modules/@ibcampusmanaus/web-app-components/src/plugins/maska.ts";
import api_pEMK3qH6PA from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-commons@1.6.0_vue@3.5.11/node_modules/@ibcampusmanaus/web-app-commons/src/plugins/api.ts";
import remoteConfig_client_H3sAbAFkEh from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-commons@1.6.0_vue@3.5.11/node_modules/@ibcampusmanaus/web-app-commons/src/plugins/remoteConfig.client.ts";
export default [
  payload_plugin_Uj19vU8f8S,
  revive_payload_client_Q5bIi1lQFP,
  unhead_67WpaHIo4T,
  router_00A0iku6p4,
  _0_siteConfig_yuVpx2BuF1,
  payload_client_lVkB9OdY1z,
  navigation_repaint_client_Xsl3dYcpES,
  check_outdated_build_client_3nZPHodPC9,
  chunk_reload_client_0ranjmRsAj,
  plugin_vue3_dRhX6C5ccH,
  plugin_9jT3W5OS8r,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Kfk6Y9ARSL,
  slideovers_J3lTlazylS,
  modals_Gt9lJBsJi3,
  colors_U7zLVPBgb6,
  plugin_client_1rze2VsG7c,
  plugin_QbA93dd8mi,
  plugin_client_J56iT8A0ys,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_OefBY4RPZv,
  scrollbars_client_00TO0odwi6,
  presets_QvOK6CALx7,
  variables_gbKpdJMtox,
  maska_70kEk31DXW,
  api_pEMK3qH6PA,
  remoteConfig_client_H3sAbAFkEh
]