import validate from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/middleware/admin.ts"),
  auth: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/middleware/auth.ts"),
  guest: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/middleware/guest.ts"),
  leader: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/middleware/leader.ts")
}