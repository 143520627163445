export default defineAppConfig({
  header: {
    links: [
      {
        to: '/',
        'aria-label': 'Início',
        label: 'Início',
      },
      {
        to: '/conectar',
        'aria-label': 'Conectar',
        label: 'Conectar',
      },
      {
        to: '/sobre',
        'aria-label': 'Sobre',
        label: 'Sobre',
      },
    ],
  },
});
