import { default as acesso_45negadoG9TUkhsRejMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/pages/acesso-negado.vue?macro=true";
import { default as autenticacao6HVeJF2zhkMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/pages/autenticacao.vue?macro=true";
import { default as indexZikKnRM0krMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/conectar/index.vue?macro=true";
import { default as indexFNgznXn86OMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/escalas/index.vue?macro=true";
import { default as indexclS7R6KqKiMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/index.vue?macro=true";
import { default as indexaofenRVTOxMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/ministerios/index.vue?macro=true";
import { default as _91turma_93W01lN7MemIMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/voluntariar/[turma].vue?macro=true";
import { default as index3XxCCk15KwMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/voluntarios/index.vue?macro=true";
import { default as indexmA6bPt0GkJMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.19.0_typescript@5.6.2_vue@3.5.11/node_modules/@ibcampusmanaus/web-app-survey/src/pages/chat/index.vue?macro=true";
import { default as indexDgWNgYjYAgMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/conectar/checkin/index.vue?macro=true";
import { default as indexFHWkRcGAKOMeta } from "/home/runner/work/web-app/web-app/src/pages/conectar/index.vue?macro=true";
import { default as inscricaoUrV60J2dQ7Meta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/conectar/inscricao.vue?macro=true";
import { default as turmasYVJHmpNGoTMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/conectar/turmas.vue?macro=true";
import { default as entrarJZv6pTJQBhMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/pages/entrar.vue?macro=true";
import { default as indexaANckcH8JrMeta } from "/home/runner/work/web-app/web-app/src/pages/index.vue?macro=true";
import { default as levitas1oudxqKcq1Meta } from "/home/runner/work/web-app/web-app/src/pages/levitas.vue?macro=true";
import { default as _2024UlInjGHZkQMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.19.0_typescript@5.6.2_vue@3.5.11/node_modules/@ibcampusmanaus/web-app-survey/src/pages/pesquisa/2024.vue?macro=true";
import { default as respostas4CBpdO76F3Meta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.19.0_typescript@5.6.2_vue@3.5.11/node_modules/@ibcampusmanaus/web-app-survey/src/pages/pesquisa/respostas.vue?macro=true";
import { default as politica_45privacidade4Q04hFoQPOMeta } from "/home/runner/work/web-app/web-app/src/pages/politica-privacidade.vue?macro=true";
import { default as sairYpFDxofzBoMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/pages/sair.vue?macro=true";
import { default as indexDRXErTWuQ7Meta } from "/home/runner/work/web-app/web-app/src/pages/sobre/index.vue?macro=true";
import { default as component_45stubyH2oRuLMmIMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubyH2oRuLMmI } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.10_eslint@9.12.0_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "acesso-negado",
    path: "/acesso-negado",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/pages/acesso-negado.vue")
  },
  {
    name: "autenticacao",
    path: "/autenticacao",
    meta: autenticacao6HVeJF2zhkMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/pages/autenticacao.vue")
  },
  {
    name: "central-conectar",
    path: "/central/conectar",
    meta: indexZikKnRM0krMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/conectar/index.vue")
  },
  {
    name: "central-escalas",
    path: "/central/escalas",
    meta: indexFNgznXn86OMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/escalas/index.vue")
  },
  {
    name: "central",
    path: "/central",
    meta: indexclS7R6KqKiMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/index.vue")
  },
  {
    name: "central-ministerios",
    path: "/central/ministerios",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/ministerios/index.vue")
  },
  {
    name: "central-voluntariar-turma",
    path: "/central/voluntariar/:turma()",
    meta: _91turma_93W01lN7MemIMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/voluntariar/[turma].vue")
  },
  {
    name: "central-voluntarios",
    path: "/central/voluntarios",
    meta: index3XxCCk15KwMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/central/voluntarios/index.vue")
  },
  {
    name: "chat",
    path: "/chat",
    meta: indexmA6bPt0GkJMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.19.0_typescript@5.6.2_vue@3.5.11/node_modules/@ibcampusmanaus/web-app-survey/src/pages/chat/index.vue")
  },
  {
    name: "conectar-checkin",
    path: "/conectar/checkin",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/conectar/checkin/index.vue")
  },
  {
    name: "conectar",
    path: "/conectar",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/conectar/index.vue")
  },
  {
    name: "conectar-inscricao",
    path: "/conectar/inscricao",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/conectar/inscricao.vue")
  },
  {
    name: "conectar-turmas",
    path: "/conectar/turmas",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-central@1.9.2/node_modules/@ibcampusmanaus/web-app-central/src/pages/conectar/turmas.vue")
  },
  {
    name: "entrar",
    path: "/entrar",
    meta: entrarJZv6pTJQBhMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/pages/entrar.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/index.vue")
  },
  {
    name: "levitas",
    path: "/levitas",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas.vue")
  },
  {
    name: "pesquisa-2024",
    path: "/pesquisa/2024",
    meta: _2024UlInjGHZkQMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.19.0_typescript@5.6.2_vue@3.5.11/node_modules/@ibcampusmanaus/web-app-survey/src/pages/pesquisa/2024.vue")
  },
  {
    name: "pesquisa-respostas",
    path: "/pesquisa/respostas",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.19.0_typescript@5.6.2_vue@3.5.11/node_modules/@ibcampusmanaus/web-app-survey/src/pages/pesquisa/respostas.vue")
  },
  {
    name: "politica-privacidade",
    path: "/politica-privacidade",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/politica-privacidade.vue")
  },
  {
    name: "sair",
    path: "/sair",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-auth@1.3.0/node_modules/@ibcampusmanaus/web-app-auth/src/pages/sair.vue")
  },
  {
    name: "sobre",
    path: "/sobre",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/sobre/index.vue")
  },
  {
    name: component_45stubyH2oRuLMmIMeta?.name,
    path: "/15anos",
    component: component_45stubyH2oRuLMmI
  }
]